import React from "react"
import { Helmet } from "react-helmet"

import config from "../../config"

const addThisUrl = `https://s7.addthis.com/js/300/addthis_widget.js#pubid=${config.ADD_THIS_ID}`

function AddThis() {
  return (
    <Helmet>
      <script type="text/javascript" src={addThisUrl} async></script>
    </Helmet>
  )
}

export default AddThis
