import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"
import Bio from "../components/bio"
import useSiteMetadata from '../components/use-site-metadata';
import AddThis from "../components/addthis"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = ({ location, data: { previous, next, post: page } }) => {
  const { siteUrl } = useSiteMetadata();
  const featuredImage = {
    fluid: page.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: page.featuredImage?.node?.alt || ``,
  }

  let postMeta = []
  
  if(featuredImage.fluid) {
    const width = featuredImage.fluid.maxWidth;
    const height = featuredImage.fluid.maxHeight;

    postMeta = [
      { name: "isPage", content: "yes" },
      { name: "og:image:width", content: width },
      { name: "og:image:height", content: height },
    ]
  }

  const imageSrc = `${siteUrl}${featuredImage?.fluid?.src}`

  return (
    <Layout locationHref={location.href}>
      <SEO
        meta={postMeta}
        title={page.title}
        description={page.excerpt}
        url={location.href}
        imageSrc={imageSrc}        
      />
      <Bio />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{parse(page.title)}</h1>
          {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
              style={{ marginBottom: 50 }}
            />
          )}          
        </header>
        {!!page.content && (
          <section itemProp="articleBody">{parse(page.content)}</section>
        )}
        <hr />
        <footer></footer>
      </article>
      <AddThis/>
    </Layout>
  )
}

export default PageTemplate

export const pageQuery = graphql`
  query PageById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPageId: String
    $nextPageId: String
  ) {
    # selecting the current post by id
    post: wpPage(id: { eq: $id }) {
      id
      content
      title,
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }      
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPage(id: { eq: $previousPageId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPage(id: { eq: $nextPageId }) {
      uri
      title
    }
  }
`
